<template>
  <div id="section-content" class="container container-fluid">
    <div class="bar-back">
      <router-link to="/member/main"><i class="fas fa-chevron-left"></i> หน้าหลัก</router-link>
    </div>
    <div class="p-2 w-100 bg-light main-content align-self-stretch" style="min-height: calc((100vh - 176px) - 50px)">
      <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-1 pb-0">
        <div class="lotto-title">
          <h4><i class="fas fa-exclamation-triangle"></i> ธนาคารปิดปรับปรุง</h4>
        </div>
      </div>
      <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 xtarget col-lotto">
        <div class="text-center my-4">
          <span style="font-size: 100px; line-height: 1.2"><i class="fas fa-ban text-danger"></i></span>
          <h4 class="text-danger" style="font-family: inherit">
            ธนาคารปิดปรับปรุงประจำวัน 22.50 - 0.10 โปรดรอทำรายการถอนใหม่นอกเวลาดังกล่าว
          </h4>
        </div>
        <div class="alert alert-danger text-center">กรุณา คลิกที่ปุ่ม <b>ย้อนกลับ</b> เพื่อทำรายการใหม่อีกครั้ง</div>
      </div>
      <div class="bg-white p-2 rounded shadow-sm w-100 mb-5">
        <router-link to="/member/main" class="btn btn-dark btn-block text-white">
          <i class="fas fa-chevron-left"></i> ย้อนกลับ
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style>
</style>
